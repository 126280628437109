<template>
  <v-btn text icon @click="onToggle">
    <active-icon :active="active" />
  </v-btn>
</template>
<script lang="ts">
import { Vue, Component, VModel } from "vue-property-decorator";
import ActiveIcon from "@/components/common/ActiveIcon.vue";

@Component({
  components: { ActiveIcon },
})
export default class SwitchCheckmark extends Vue {
  @VModel({ type: Boolean }) active!: boolean;

  onToggle() {
    const bActive = !this.active;
    this.$emit("input", bActive);
    this.$emit("change", bActive);
  }
}
</script>
