<template>
  <v-row>
    <v-col cols="4" xl="3">
      <v-switch
        :label="$t('active')"
        v-model="obPaymentMethod.active"
      ></v-switch>
    </v-col>

    <v-col cols="4" xl="3">
      <v-switch
        :label="$t('paymentmethod.inaccount')"
        v-model="obPaymentMethod.in_account"
      ></v-switch>
    </v-col>

    <v-col cols="4" xl="3">
      <v-switch
        :label="$t('paymentmethod.ininvoice')"
        v-model="obPaymentMethod.in_invoice"
      ></v-switch>
    </v-col>

    <v-col cols="4" xl="3">
      <v-switch
        :label="$t('paymentmethod.configurable')"
        v-model="obPaymentMethod.configurable"
      ></v-switch>
    </v-col>

    <v-col cols="12" lg="6" xl="8">
      <form-field-text v-model="obPaymentMethod.name" required autofocus />
    </v-col>

    <v-col cols="12" lg="6" xl="4">
      <form-field-text v-model="obPaymentMethod.code" label="code" required />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Vue, Component, VModel } from "vue-property-decorator";
import { PaymentMethod } from "@planetadeleste/vue-mc-gw";

@Component
export default class PaymentmethodsSettings extends Vue {
  @VModel({ type: Object, default: () => new PaymentMethod() })
  obPaymentMethod!: PaymentMethod;
}
</script>
