<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="12">
        <data-table
          :headers="headers"
          :items="obCollection.getModelList()"
          :options="pagination"
          :total="serverItemsLength"
          :last-page="serverLastPage"
          :loading="bLocalLoading"
          btn-action-path="paymentmethods"
          btn-action-item-key-id="id"
          hide-btn-action-view
          is-route-name
          @delete="deleteItem"
          @update:sort-by="onSortBy"
          @update:sort-desc="onSortDesc"
          @update:options="onPagination"
          @update:page="onPage"
        >
          <template v-slot:[`item.active`]="{ item }">
            <!-- <active-icon :active="item.active" /> -->
            <switch-checkmark
              @change="toggle($event, 'active', item)"
              :value="item.active"
            />
          </template>

          <template v-slot:[`item.in_account`]="{ item }">
            <switch-checkmark
              @change="toggle($event, 'in_account', item)"
              :value="item.in_account"
            />
          </template>

          <template v-slot:[`item.in_invoice`]="{ item }">
            <switch-checkmark
              @change="toggle($event, 'in_invoice', item)"
              :value="item.in_invoice"
            />
          </template>

          <template v-slot:[`item.configurable`]="{ item }">
            <switch-checkmark
              @change="toggle($event, 'configurable', item)"
              :value="item.configurable"
            />
          </template>

          <template #[`item.name`]="{ item }">
            <v-btn
              :to="{ name: 'paymentmethods.update', params: { id: item.id } }"
              text
              color="primary"
            >
              {{ item.name }}
            </v-btn>
          </template>

          <template #[`item.actions`]="{ item }">
            <module-actions :value="item.id" @delete="deleteItem" />
          </template>
        </data-table>
      </v-col>
    </v-row>
    <paymentmethods-form
      v-model="obItem"
      :open.sync="displayForm"
      :key="$route.params.id"
    />
  </v-col>
</template>

<script lang="ts">
import { Mixins, Component } from "vue-property-decorator";
import PaymentmethodsMixin from "@/modules/paymentmethods/mixins/PaymentmethodsMixin";
import ActiveIcon from "@/components/common/ActiveIcon.vue";
import PaymentmethodsForm from "@/modules/paymentmethods/components/Form.vue";
import SwitchCheckmark from "@/components/form/fields/SwitchCheckmark.vue";
import type { DataTableHeader } from "vuetify";
import type { PaymentMethodData } from "@planetadeleste/vue-mc-gw";
import { PaymentMethod } from "@planetadeleste/vue-mc-gw";

@Component({
  components: {
    ActiveIcon,
    PaymentmethodsForm,
    SwitchCheckmark,
  },
})
export default class PaymentmethodsList extends Mixins(PaymentmethodsMixin) {
  isList = true;

  onMounted() {
    const arHeaders: DataTableHeader[] = [
      { text: "code", value: "code" },
      { text: "paymentmethod.inaccount", value: "in_account" },
      { text: "paymentmethod.ininvoice", value: "in_invoice" },
      { text: "paymentmethod.configurable", value: "configurable" },
    ];
    this.addDTHeaders(arHeaders);

    this.index();
  }

  async toggle(bValue: boolean, sProp: string, obItemData: PaymentMethodData) {
    const obModel = new PaymentMethod(obItemData);
    const obPaymentMethod = this.obCollection.find({ id: obItemData.id });
    if (obPaymentMethod) {
      obModel.set(sProp, bValue);
      obModel.store().then((obResponse) => {
        if (obResponse) {
          const obData = obResponse.getData().data;
          obPaymentMethod.set(obData);
        }
      });
    }
  }
}
</script>
